$(document).on("turbolinks:before-cache", function() {
    $('#context-multi-select').remove();
})

$(document).on("turbolinks:load",function(){

	mr.documentReady(mr.setContext());    
    mr.components.documentReady.push(mr.toggleClass.documentReady);

    $('.accordion__title').on('click', function(){
        mr.accordions.activatePanel($(this));
    });

    $('.accordion').each(function(){
        var accordion = $(this);
        var minHeight = accordion.outerHeight(true);
        accordion.css('min-height',minHeight);
    });	

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();    

    // change textarea size based on content
    $('.smart-size textarea').each(function () {
        this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
        }).on('input', function () {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    });

    $('#context-multi-select').multiselect({
        buttonContainer: '<div style="float: left;"></div>',
        
        templates: {                    
            ul: '<ul style="max-height: 500px;overflow-x: hidden;" class="multiselect-container dropdown-menu"></ul>',
            //button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"></button>',∂
            li: '<li class="dropdown-item"><a><i style="" class="fa fa-check"></i><label></label></a></li>',
            divider: '<li class="multiselect-item divider"></li>',
            liGroup: '<li style="padding: 5px 10px;" class="multiselect-item group"><label class="multiselect-group clean"></label></li>'
        },
        onChange: function(element, checked) {
            translatefx();
        }            
    });

      
    $(function() {
        setTimeout(function() {
            if($(".document_texts").length > 0){
                return $('.document_texts').infinitePages({
                    //debug: true,
                    loading: function() {
                        return $(this).text('Loading next page...');
                    },
                    error: function() {
                        return $(this).button('There was an error, please try again');
                    },
                    success: function(){
                        if (last_focus_id){
                        last_focus_id_quill = quills[last_focus_id];
                        last_focus_id_quill.focus();
                        }
                    },
                });
            }
        }, 500);
    });
      

})

$(document).on('turbolinks:visit', function() {        
    if($(".document_texts").length > 0){$('.document_texts').infinitePages('destroy');}
});

// does a jquery contains but ignores all \n, \t, and spaces to match
$.expr[":"].contains_without_newline = $.expr.createPseudo(function(arg) {
    return function( elem ) {
        str = $(elem).text().replace(/(\n)+/g, " ");
        str = str.replace(/(\t)+/g, "");
        str = str.replace(/&nbsp;/, " ");
        return str.indexOf(arg) >= 0;
    };
});

// check if element in array
window.isInArray = function(value, array) {
    temp = false
    array.forEach(function (item, index) {
        if (item.includes(value) == true){            
            temp = true;
        }
    })
    return temp;
}

