/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
Rails.start();
Turbolinks.start();

import ahoy from 'ahoy.js';
require('bootstrap/dist/js/bootstrap')
require('bootstrap-multiselect/dist/js/bootstrap-multiselect')

import '../src/javascript/granim.min.js';

import * as introJs from 'intro.js'
window.introJs = introJs;

import '../src/javascript/scripts.js';
import '../src/javascript/custom.js.erb';

import '../src/stylesheets/stylesheets.scss';
